import {ENV} from '../../../env';

export default function getWidgetDomain() {
  // TODO: Remove after testing.
  if (ENV === 'stage') {
    return 'xbs-4995.app.x-bees.com';
  }

  switch (ENV) {
    // @ts-ignore
    case 'stage':
      return 'stage.app.x-bees.com';
    case 'stable':
      return 'stage.app.x-bees.com';
    default:
      return 'app.x-bees.com';
  }
}
