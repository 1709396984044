import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box} from '@mui/material';
import Button from '@mui/material/Button';

import {WidgetRouteTimeFrameDay} from '@wildix/xbees-kite-client';
import {WidgetRouteRule} from '@wildix/xbees-kite-client/dist-types/models/models_0';

import {set} from 'lodash';

import {WidgetFormData} from '../../types';
import WidgetWorkHoursTimeFrameRuleFieldset from './WidgetWorkHoursTimeFrameRuleFieldset';

interface WidgetWorkHoursRulesFieldsetProps {
  form: UseFormReturn<WidgetFormData>;
}

export default function WidgetWorkHoursRulesFieldset(props: WidgetWorkHoursRulesFieldsetProps) {
  const {form} = props;
  const {watch, formState, setValue} = form;
  const {isSubmitting} = formState;

  const rules = watch('workHoursRules') || [];

  const onAddClick = () => {
    const values = structuredClone(form.getValues());

    const newRules: WidgetRouteRule[] = [
      ...rules,
      {
        timeFrame: {
          timeZone: '-',
          day: rules.length === 0 ? WidgetRouteTimeFrameDay.WEEKDAYS : WidgetRouteTimeFrameDay.MONDAY,
          startMinute: 480,
          endMinute: 1080,
        },
      },
    ];
    const newValues = set(values, 'workHoursRules', newRules);

    form.reset(newValues);
  };

  const onDeleteClick = (index: number) => {
    if (rules.length === 0) {
      return;
    }

    const values = structuredClone(form.getValues());
    const newRules = rules.filter((_, ruleIndex) => ruleIndex !== index);

    if (newRules.length === 0) {
      setValue('workHoursRules', undefined);

      form.reset(set(values, 'workHoursRules', undefined));
    } else {
      form.reset(set(values, 'workHoursRules', newRules));
    }
  };

  return (
    <Box>
      <Box>
        {rules.map((rule, index) => {
          if (rule.timeFrame) {
            return (
              <WidgetWorkHoursTimeFrameRuleFieldset
                key={JSON.stringify([rule, index])}
                form={form}
                path={`workHoursRules[${index}].timeFrame`}
                onDelete={() => onDeleteClick(index)}
              />
            );
          }
        })}
      </Box>
      <Box mt={rules.length ? 2 : 0}>
        <Button variant="outlined" size="small" disabled={isSubmitting} onClick={onAddClick}>
          Add rule
        </Button>
      </Box>
    </Box>
  );
}
